.projectsContainer {
  position: relative;
  z-index: 1;
  padding-top: 50px;
  padding-bottom: 100px;
}
.wrapper {
  max-width: 1317px;
  width: 95%;
  margin: 0 auto;
}
.projects {
  justify-content: center;
  margin: 0 auto;
}

.cardContainer {
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 0;
}
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  padding-bottom: 40px;
}
.heading {
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 40px;
  text-align: center;

  /* Backround Light */

  color: var(--title);
}
.searchAndFilter {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
}
.searchIcon {
  color: var(--textColor);
  font-size: 18px;
}
.inputContainer {
  display: flex;
  align-items: center;

  padding: 5px 15px;
  background: #f9fbfe;

  border: 1px solid #0e0c15;
  border-radius: 8px;

  width: 296px;
}
.input {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 130%;
  color: var(--textColor);
  width: 100%;
  padding: 8px 10px;
  background: transparent;
  width: 100%;
  border: none;
  outline: none;
}

.icon {
  width: 18px;
  height: 18px;
}
.text {
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 125%;
  color: var(--textColor);
}
/* filter */
.dropdown {
  display: flex;
  gap: 8px;
  align-items: center;
  position: relative;
  border: 1px solid #0e0c15;
  border-radius: 8px;

  padding: 7px 15px;
  background: #f9fbfe;
}
.dropDownItems {
  position: absolute;
  z-index: 5;
  min-width: 100%;
  top: 50px;
  right: 0px;

  border: 1px solid #0e0c15;
  background: var(--white);

  border-radius: 8px;

  padding: 8px 5px;

  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 8px;
}
.sortValue {
  font-family: "Sora";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 23px;

  color: var(--textColor);
  width: 100%;
  padding: 4px 0;
  display: flex;
  align-items: center;
  text-transform: capitalize;
}
.sort {
  font-family: var(--fontFamily);
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 143%;
  color: var(--titleColor);
  border-right: 1px solid var(--titleColor);
  padding-right: 12px;
  display: block;
}
.sortBy {
  padding-left: 12px;
  display: block;
}
.dropDownItem {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  color: var(--textColor);
  width: 100%;
  padding: 10px 14px;
}
.dropDownItem:hover {
  border-radius: 16px;
  color: var(--titleColor);
}
.dropDownItems .dropDownItem {
  padding-right: 20px;

  cursor: pointer;
}
.arrow {
  color: var(--textColor);
  font-size: 24px;
  cursor: pointer;
}
@media only screen and (max-width: 1399px) {
  .projects {
    gap: 20px 10px;
  }
}
@media only screen and (max-width: 1280px) {
  .projects {
  }
  .buttonContainer {
    gap: 12px;
  }
}
@media only screen and (max-width: 991px) {
  .header {
    flex-direction: column;
    align-items: center;
  }
}
@media only screen and (max-width: 520px) {
  .searchAndFilter {
    flex-direction: column-reverse;
  }
}
