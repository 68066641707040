.progressBar {
  display: flex;
  align-items: center;
  padding: 0 5px;

  height: 22px;
  width: 100%;
  background: rgba(243, 186, 0.25);
  border: 0.774305px solid rgb(243, 186, 0.25);
  border-radius: 18.5833px;
  transition: background-color 0.5s ease-out;
}

.progress {
  height: 100%;
  background: #f3ba2f;
  border-radius: 200;
  height: 12px;
  transition: width 0.5s ease-out;
  border-radius: 8px;
}
