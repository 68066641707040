.wrapper {
  background: var(--white);
  box-shadow: 0px 1.15px 3.31px #e9e9e9;
  border-radius: 18.4768px;
  padding: 15px;
}
.presaleStartIn {
  display: flex;
  flex-direction: column;
  gap: 25px;
}
.presaleStart {
  font-style: normal;
  font-weight: 400;
  font-size: 12.3179px;
  line-height: 155%;
  text-align: center;
  color: var(--titleColor);
}
.progressContainer {
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding: 0 8px;
}
.price {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12.3889px;
  line-height: 200%;
  text-transform: uppercase;
  color: var(--titleText);
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.connectButton {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 125%;
  color: var(--white);
  background: #f3ba2f;
  border-radius: 10.7782px;
  padding: 12px 15px;
  width: 100%;
  cursor: pointer;
}
.amountText {
  font-style: normal;
  font-weight: 400;
  font-size: 16.9371px;
  line-height: 113%;

  color: var(--titleColor);
  padding-bottom: 5px;
}
.inputContainer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #e0e0e0;
  border-radius: 10.7782px;
  padding: 0 15px;
}
.input {
  width: 100%;

  font-style: normal;
  font-weight: 400;
  font-size: 13.8576px;
  line-height: 138%;

  border: none;
  outline: none;
  padding: 12px 0px;
  background: transparent;
}
.max {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 136%;

  color: var(--titleColor);
}
@media only screen and (max-width: 520px) {
  .progressContainer {
    padding: 0;
  }
  .progressText {
    font-size: 13px;
  }
  .connectButton {
    font-size: 16px;
  }
  .price {
    font-size: 11px;
  }
}
