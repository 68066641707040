.wrapper {
  background: #f8f8f9;
  border-radius: 0px 15.87px 15.87px 0px;
  padding: 25px;
  width: 100%;
}
.title {
  font-style: normal;
  font-weight: 600;
  font-size: 23px;
  line-height: 160%;

  color: var(--titleColor);
  padding-bottom: 40px;
}
.gradient {
  background: #f3ba2f;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}
.form {
  display: flex;
  flex-direction: column;
  gap: 35px;
}

.label {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 10px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 137%;
  color: var(--titleColor);

  padding-bottom: 8px;
  word-break: keep-all;
}
.rightLabel {
  margin-left: auto;
}
.inputContainer {
  background: #ffffff;
  border-radius: 28px;
  display: flex;
  align-items: center;
  gap: 15px;
  padding: 0 18px;
}
.input {
  border: none;
  outline: none;
  background: transparent;
  width: 100%;
  padding: 18px 0;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 177%;
  color: var(--textColor);
}
.connectButton {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 125%;
  color: var(--white);
  background: #f3ba2f;
  border-radius: 28px;

  padding: 12px 15px;
  width: 100%;
  cursor: pointer;
  margin-top: auto;
}
@media only screen and (max-width: 1350px) {
  .label {
    font-size: 16px;
  }
}
@media only screen and (max-width: 520px) {
  .wrapper {
    padding: 15px;
  }
  .form {
    gap: 25px;
  }

  .title {
    padding-top: 30px;
  }
  .label {
    font-size: 14px;
  }
  .input {
    padding: 12px 0;
    font-size: 12px;
  }
  .connectButton {
    font-size: 18px;
  }
  .inputContainer {
    padding: 0 10px;
  }
  .icon {
    width: 20px;
  }
}
