.form {
  padding-top: 150px;
  padding-bottom: 100px;
  max-width: 1150px;
  width: 95%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 15px;
}

.inputContainer {
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding-bottom: 8px;
}
.labelContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.label {
  padding: 8px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 100%;
  color: var(--titleColor);
}
.input {
  border: none;
  outline: none;
  background: transparent;
  width: 100%;
  padding: 8px 12px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 177%;
  color: var(--textColor);
  background: #f8f8f9;
  border-radius: 10px;
}

.inputDisabled {
  background: #f8f8f9;
  filter: blur(1.5px);
  border-radius: 14px;
}
.textArea {
  height: 163px;
  width: 100%;
}
.connectButton {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 125%;
  color: var(--white);
  background: #F3BA2F;
  border-radius: 28px;

  padding: 12px 15px;
  width: 100%;
  cursor: pointer;
  margin-top: auto;
}
@media only screen and (max-width: 991px) {
  .form {
    max-width: 600px;
    margin: 0 auto;
  }
  .wrapper {
    grid-template-columns: 1fr;
    width: 100%;
    gap: 40px;
  }
}
@media screen and (max-width: 450px) {
  .form {
    padding-top: 110px;
  }
}
