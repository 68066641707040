.mainContainer {
  background: var(--secondaryBg);
  box-shadow: 0px 7.15335px 14.3067px #e9e9e9;

  border-radius: 19px;
  width: 100%;
}
.wrapper {
  padding: 20px;
  width: 100%;
}

.chartContainer {
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  position: relative;
}
.textContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  position: absolute;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.title {
  font-style: normal;
  font-weight: 300;
  font-size: 12.9507px;
  line-height: 120%;
  text-align: center;
  text-transform: uppercase;
  color: var(--titleColor);
}
.text {
  font-style: normal;
  font-weight: 400;
  font-size: 23.909px;
  line-height: 120%;

  text-align: center;

  color: var(--titleColor);
}
@media only screen and (max-width: 450px) {
  .chartContainer {
    width: 280px;
  }
  .textContainer {
    top: 58%;
  }
}
@media only screen and (max-width: 350px) {
  .chartContainer {
    width: 240px;
  }
  .textContainer {
    top: 60%;
  }
}
