.wrapper {
  display: grid;
  grid-template-columns: 1fr auto 1fr auto 1fr auto 1fr;
  align-items: center;
  gap: 8px;
  color: var(--titleColor);
  margin: 0 auto;
}
.contain {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 10px;
}
.counterTextWrapper {
  display: grid;
  grid-template-columns: 1fr auto 1fr auto 1fr auto 1fr;
  align-items: center;
  gap: 8px;
  padding-top: 5px;
}
.closedIn {
  font-style: normal;
  font-weight: 400;
  font-size: 12.3889px;
  line-height: 100%;
  color: var(--titleColor);
}
.count {
  font-style: normal;
  font-weight: 600;
  font-size: 21.55px;
  line-height: 100%;

  text-align: center;

  color: var(--titleColor);
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 20px;
  padding: 12px;
  box-shadow: 0px 1.15px 3.31px #e9e9e9;
  background: rgb(233, 233, 233, 0.4);
}
@media only screen and (max-width: 520px) {
  .count {
    font-size: 16px;
  }
}
