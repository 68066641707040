.wrapper {
  border-radius: 0px 15.87px 15.87px 0px;
  padding: 25px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 25px;
}
.title {
  font-style: normal;
  font-weight: 600;
  font-size: 23px;
  line-height: 160%;

  color: var(--titleColor);
}
.gradient {
  background: #f3ba2f;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}
.text {
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 163%;
  color: var(--titleColor);
}
.list {
  padding-left: 20px;
}
.connectButton {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 125%;
  color: var(--white);
  background: #f3ba2f;
  border-radius: 28px;

  padding: 12px 15px;
  width: 100%;
  cursor: pointer;
  margin-top: auto;
}
@media only screen and (max-width: 1350px) {
  .text {
    font-size: 16px;
  }
}
@media only screen and (max-width: 520px) {
  .wrapper {
    gap: 15px;
    padding: 15px;
  }

  .text {
    font-size: 14px;
  }
  .connectButton {
    font-size: 18px;
  }
}
