.wrapper {
  border: 0.731393px solid rgba(14, 12, 21, 0.7);
  border-radius: 22.4839px;
  padding: 12px 25px;
  display: flex;
  justify-content: space-between;
}
.textContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding: 10px 0;
}
.title {
  font-family: "Source Code Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 16.3126px;
  line-height: 130%;
  color: var(--titleColor);
}
.gradient {
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-fill-color: transparent;
  color: transparent;
  background-image: linear-gradient(
    91.26deg,
    #8ad4ec 0%,
    #ef96ff 21.74%,
    #ff56a9 54.03%,
    #ffaa6c 85.28%
  );
}
.image {
  max-width: 154px;
  width: 100%;
}
.button {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 13.3px;
  line-height: 150%;
  color: var(--titleColor);
  border: none;
  outline: none;
  border: 1px solid #f3ba2f;
  border-radius: 8px;
  background: transparent;
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 8px 10px;
  cursor: pointer;
}
.arrow {
  width: 19.61px;
}

@media only screen and (max-width: 400px) {
  .wrapper {
    padding: 8px 15px;
  }
}
@media only screen and (max-width: 400px) {
  br {
    display: none;
  }
}
